a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-weight: 100;
}

.logo {
    display: flex;
    align-items: center;
    padding: 1vh;
}

#logo {
    width: 200px;
}

.links {
    display: flex;
    align-items: center;
    padding-right: 15vh;
    gap: 80px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0vh 3vh 0vh 3vh;
    background-color: #fffffe;
    box-shadow: 1px 1px 3px;
    position: fixed;
    /* top: 5px;
    left: 5px; */
    width: 100%;
    z-index: 1;
    top: 0px;
}

#order-link {
    background-color: rgb(253, 248, 106);
    color: black;
    padding: 1.5vh;
    border-radius: 10px;
    border: solid;
    border-color: rgb(158, 158, 158);
    font-weight: 600;
}

#order-link:hover {
    cursor: pointer;
    background-color: rgb(251, 244, 26);
}


@media only screen and (max-width: 460px)  {
    .header {
        justify-content: space-between;
    }

    .links {
        padding-right: 7vh;
    }

    #logo {
        width: 120px;
    }
}