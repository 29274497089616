@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    font-family: "Open Sans", sans-serif;
}

#img1 {
    width: 300px;
    border-radius: 20px;
}

.home-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 32vh 10vh 25vh 10vh;
    text-align: center;
    gap: 100px;
}

.home-body2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding:0vh 10vh 25vh 10vh;
    text-align: center;
    gap: 100px;
}

.home-body3 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding:0vh 10vh 25vh 10vh;
    text-align: center;
    gap: 100px;
}


@media only screen and (max-width: 480px) {
    .home-body {
        flex-direction: column;
        padding: 18vh 2vh 10vh 2vh;
        gap: 10px;
    }

    .home-body2 {
        flex-direction: column;
        padding:0vh 2vh 10vh 2vh;
        gap: 10px;
    }

    .home-body3 {
        flex-direction: column;
        padding:0vh 2vh 10vh 2vh;
        gap: 10px;
    }
}