.footer-body {
    background-color: #e3cb9c;
    color: black;
    padding: 7vh;
}

hr {
    border-top: 2px solid black;
    border-bottom: 1px solid rgb(152, 149, 149);
    border-radius: 10px;
}

h3 {
    font-weight: 100;
}

@media only screen and (max-width: 480px) {
    .footer-body {
        padding: 2vh 2vh 5vh 2vh;
    }
}