* {
  margin: 0;
}

/**
 * Tables
 */
 table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}

thead th {
  border-bottom: 2px solid #dedede;
}

tfoot th {
  border-top: 2px solid #dedede;
}

td {
  border-bottom: 1px solid #dedede;
}

th,
td {
  text-align: left;
  padding: 0.5rem;
}

caption {
  padding: 1rem 0;
  caption-side: bottom;
  color: #ababab;
}

.striped-table tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.contain-table {
  overflow-x: auto;
}

@media (min-width: 600px) {
  .contain-table {
    width: 100%;
  }
}

header {
  padding: 2vh;
}

.contain-table {
  padding: 2vh;
}

/**
 * Helpers
 */
 .clearfix::before,
 .clearfix::after {
   content: ' ';
   display: block;
 }
 
 .clearfix:after {
   clear: both;
 }
 
 .text-left {
   text-align: left;
 }
 
 .text-right {
   text-align: right;
 }
 
 .text-center {
   text-align: center;
 }
 
 .text-justify {
   text-align: justify;
 }
 
 .block {
   display: block;
 }
 
 .inline-block {
   display: inline-block;
 }
 
 .inline {
   display: inline;
 }
 
 .vertical-center {
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .responsive-image {
   max-width: 100%;
   height: auto;
 }
 
 .show {
   display: block !important;
 }
 
 .hide {
   display: none !important;
 }
 
 .invisible {
   visibility: hidden;
 }
 
 .no-padding-top {
   padding-top: 0;
 }
 
 .no-padding-bottom {
   padding-bottom: 0;
 }
 
 .padding-top {
   padding-top: 2rem;
 }
 
 .padding-bottom {
   padding-bottom: 2rem;
 }
 
 .no-margin-top {
   margin-top: 0;
 }
 
 .no-margin-bottom {
   margin-bottom: 0;
 }
 
 .margin-top {
   margin-top: 2rem;
 }
 
 .margin-bottom {
   margin-bottom: 2rem;
 }
 
 .alternate-background {
   background: #fafafa;
   color: #404040;
 }
 
 .space-between {
   justify-content: space-between;
 }
 
 .justify-center {
   justify-content: center;
 }
 
 .align-center {
   align-items: center;
 }
 
 .screen-reader-text {
   clip: rect(1px, 1px, 1px, 1px);
   position: absolute !important;
   height: 1px;
   width: 1px;
   overflow: hidden;
 }

 /**
 * Forms
 */
[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='url'],
[type='week'],
[type='time'],
select,
textarea {
  display: block;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 0.75rem;
  outline: none;
  background: transparent;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
  line-height: 1;
}

[type='color']:hover,
[type='date']:hover,
[type='datetime']:hover,
[type='datetime-local']:hover,
[type='email']:hover,
[type='month']:hover,
[type='number']:hover,
[type='password']:hover,
[type='search']:hover,
[type='tel']:hover,
[type='text']:hover,
[type='url']:hover,
[type='week']:hover,
[type='time']:hover,
select:hover,
textarea:hover {
  border: 1px solid #c5c5c5;
}

[type='color']:focus,
[type='color']:active,
[type='date']:focus,
[type='date']:active,
[type='datetime']:focus,
[type='datetime']:active,
[type='datetime-local']:focus,
[type='datetime-local']:active,
[type='email']:focus,
[type='email']:active,
[type='month']:focus,
[type='month']:active,
[type='number']:focus,
[type='number']:active,
[type='password']:focus,
[type='password']:active,
[type='search']:focus,
[type='search']:active,
[type='tel']:focus,
[type='tel']:active,
[type='text']:focus,
[type='text']:active,
[type='url']:focus,
[type='url']:active,
[type='week']:focus,
[type='week']:active,
[type='time']:focus,
[type='time']:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  border: 1px solid #0366ee;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #8cbcfe;
}

textarea {
  overflow: auto;
  height: auto;
}

fieldset {
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 1rem;
  margin: 1.5rem 0;
}

legend {
  padding: 0 0.5rem;
  font-weight: 600;
}

select {
  color: #404040;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==)
    right center no-repeat;
  line-height: 1;
}

select::-ms-expand {
  display: none;
}

[type='range'] {
  width: 100%;
}

label {
  font-weight: 600;
  max-width: 100%;
  display: block;
  margin: 1rem 0 0.5rem;
}

@media (min-width: 600px) {
  .split-form label {
    text-align: right;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}

input.has-error,
input.has-error:hover,
input.has-error:focus,
input.has-error:active,
select.has-error,
select.has-error:hover,
select.has-error:focus,
select.has-error:active,
textarea.has-error,
textarea.has-error:hover,
textarea.has-error:focus,
textarea.has-error:active {
  border: 1px solid #d33c40;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #f4cecf;
}

input.is-success,
input.is-success:hover,
input.is-success:focus,
input.is-success:active,
select.is-success,
select.is-success:hover,
select.is-success:focus,
select.is-success:active,
textarea.is-success,
textarea.is-success:hover,
textarea.is-success:focus,
textarea.is-success:active {
  border: 1px solid #29de7d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #97efc0;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
  color: #9a9a9a;
}